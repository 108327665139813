import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "../../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class ReportLogService {


    constructor(private http: HttpClient) { }

    getAllLogs(data): Observable<any> {
        return this.http.get<any>( environment.url + `/api/ReportLog`, {params:{...data}});
    }
}
